/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.altai-theme-admin .content-toolbar-container {
  width: 100%;
  margin-bottom: 20px;
}
.altai-theme-admin .content-toolbar {
  top: 0;
  color: #fff;
  width: 100%;
  height: 50px;
  background-color: #0a264e;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0);
  position: absolute;
  display: flex;
  top: 29px;
  z-index: 70;
  justify-content: space-between;
  border-bottom: 4px solid #0068ff;
}
.altai-theme-admin .content-toolbar .altai-admin-icon,
.altai-theme-admin .content-toolbar .icon {
  outline: none;
  border-radius: 50%;
  border: 2px solid transparent;
  height: 20px;
  width: 20px;
  background-color: transparent;
  color: #ffffff;
  margin-right: 5px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 0;
  display: inline-block;
}
.altai-theme-admin .content-toolbar .altai-admin-icon:hover,
.altai-theme-admin .content-toolbar .icon:hover {
  border: 2px solid #008cff;
  color: #008cff;
}
.altai-theme-admin .content-toolbar a.md-button.md-default-theme.md-raised[disabled],
.altai-theme-admin .content-toolbar a.md-button.md-raised[disabled],
.altai-theme-admin .content-toolbar a.md-button.md-default-theme.md-fab[disabled],
.altai-theme-admin .content-toolbar a.md-button.md-fab[disabled],
.altai-theme-admin .content-toolbar .md-button.md-default-theme.md-raised[disabled],
.altai-theme-admin .content-toolbar .md-button.md-raised[disabled],
.altai-theme-admin .content-toolbar .md-button.md-default-theme.md-fab[disabled],
.altai-theme-admin .content-toolbar .md-button.md-fab[disabled] {
  background-color: #585858;
  color: #b9b9b9 !important;
}
.altai-theme-admin .toolbar-languages {
  padding-right: 0px;
}
.altai-theme-admin .content-toolbar-items {
  color: #fff;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  padding: 5px 10px 0 10px;
}
.altai-theme-admin .content-toolbar-items ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  height: 50px;
}
.altai-theme-admin .content-toolbar-items ul li {
  padding-left: 20px;
  padding-right: 20px;
  display: table-cell;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  background-color: #EBEBEB;
  color: #fff;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  vertical-align: middle;
  height: 50px;
}
.altai-theme-admin .content-toolbar-items ul li i {
  color: #0068ff;
  padding-right: 5px;
}
.altai-theme-admin .content-toolbar-items ul li.disabled {
  cursor: default;
  color: #aaa;
}
.altai-theme-admin .content-toolbar-items ul li.disabled i {
  color: #aaa;
}
.altai-theme-admin .content-toolbar-items ul li:hover {
  background-color: #F5F5F5;
  color: black;
}
.altai-theme-admin .content-toolbar-items ul li.disabled:hover {
  background-color: #EBEBEB;
  color: #aaa;
}
.altai-theme-admin .content-toolbar-items .toolbar-items-vertical-center {
  padding-top: 0px;
  top: -8px;
  position: relative;
}
.altai-theme-admin .content-toolbar-items li.separator {
  cursor: default;
  padding-left: 0;
  padding-right: 0;
}
.altai-theme-admin .content-toolbar-items li.separator:hover {
  background-color: #EBEBEB;
}
.altai-theme-admin .content-toolbar-items .separator-lines {
  width: 2px;
  box-shadow: #F9F9F9 -1px 0 inset;
  height: 50px;
  background-color: #c9c9c9;
}
.altai-theme-admin .right {
  float: right;
}
.altai-theme-admin .left {
  float: left;
}
.altai-theme-admin .center {
  margin: auto;
}
.altai-theme-admin .action-icon {
  cursor: pointer;
}
.altai-theme-admin .altaiplatform .md-icon-button:hover {
  color: #008cff;
}
.altai-theme-admin .altaiplatform .md-icon-button {
  border-radius: 0;
}
.altai-theme-admin .altaiplatform .md-icon-button.active {
  color: #008cff;
}
.altai-theme-admin .window-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.altai-theme-admin .md-toolbar-tools {
  background-color: #2e394e !important;
}
.altai-theme-admin .window-split-left-right {
  width: 100%;
}
.altai-theme-admin .window-split-left {
  width: 600px;
  overflow-y: auto;
  float: left;
}
.altai-theme-admin .window-split-right {
  padding: 0;
  display: block;
  overflow-y: auto;
}
.altai-theme-admin .window-container.minimized .window-header {
  border-bottom: 0;
  width: 45px;
}
.altai-theme-admin .window-container.minimized .window-header .actions-right {
  justify-content: center;
}
.altai-theme-admin .window {
  flex: 1;
  display: flex;
  background-color: #fafafa;
  color: #1f1f1f;
  overflow-y: hidden;
  /*.window-content.sidebar-movement.ng-enter-active.ng-leave-active {
        overflow: hidden;
    }*/
}
.altai-theme-admin .window .windowicon {
  font-size: 0.8em;
}
.altai-theme-admin .window .window-inner {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}
.altai-theme-admin .window .toolbar-shift {
  margin-top: 54px;
}
.altai-theme-admin .window .window-header {
  display: flex;
  height: 30px;
  width: 100%;
  justify-content: space-around;
  background-color: #0a264e;
  font-size: 16px;
  /*border-bottom: 4px solid @color-toolbar-window-topborder;*/
  color: white;
}
.altai-theme-admin .window .window-header md-menu-item > .md-button,
.altai-theme-admin .window .window-header .md-button {
  font-size: 16px;
}
.altai-theme-admin .window .window-header .md-button.md-icon-button {
  margin: 0px 1px;
  height: 30px;
  min-width: 0;
  line-height: 30px;
  padding: 1px 0 0 0;
  width: 30px;
  color: white;
  min-height: 30px;
}
.altai-theme-admin .window .window-header .md-button.md-icon-button:hover {
  color: #0068ff;
}
.altai-theme-admin .window .window-header div {
  padding: 10px;
}
.altai-theme-admin .window .window-header .top-toolbar-action {
  padding-right: 10px;
}
.altai-theme-admin .window .window-header .title {
  text-align: center;
  flex: 1;
  font-size: 12px;
  letter-spacing: 0.1em;
  line-height: 30px;
}
.altai-theme-admin .window .window-header .actionarea {
  text-align: right;
}
.altai-theme-admin .window .window-innercontainer {
  /*display: flex;*/
  flex: 1;
  max-height: 100%;
}
.altai-theme-admin .window .window-innercontainer .window-innercontainer-left {
  flex: 1;
  display: flex;
}
.altai-theme-admin .window .window-topactions .actions-left,
.altai-theme-admin .window .window-header .actions-left {
  padding: 0px 0 0 10px;
  /*flex: 1;*/
}
.altai-theme-admin .window .window-topactions .actions-middle,
.altai-theme-admin .window .window-header .actions-middle {
  padding: 0px 10px 0 10px;
  /*flex: 1;*/
}
.altai-theme-admin .window .window-topactions .actions-middle,
.altai-theme-admin .window .window-header .actions-middle {
  flex: 1;
}
.altai-theme-admin .window .window-topactions .actions-right,
.altai-theme-admin .window .window-header .actions-right {
  justify-content: flex-end;
  display: flex;
  padding: 0px 10px 0 0;
}
.altai-theme-admin .window .window-topactions .action-icon:focus,
.altai-theme-admin .window .window-header .action-icon:focus {
  outline: none;
}
.altai-theme-admin .window .window-topactions {
  justify-content: space-between;
  display: flex;
  width: 100%;
  font-size: 15px;
  color: #646f82;
  background-color: #F9F9FB;
  user-select: none;
}
.altai-theme-admin .window .window-topactions .version {
  margin-left: 5px;
}
.altai-theme-admin .window .window-topactions .model-menubutton {
  float: right;
  margin-left: 20px;
}
.altai-theme-admin .window .window-content {
  flex: 1;
  display: flex;
  overflow: auto;
  height: 1px;
}
.altai-theme-admin .window .window-content .window-include {
  display: flex;
  overflow: auto;
  flex: 1;
}
.altai-theme-admin .window .window-footer {
  padding: 10px;
  color: #999999;
}
.altai-theme-admin .window .dropzone-left,
.altai-theme-admin .window .dropzone-right {
  z-index: 100;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: rgba(204, 204, 204, 0.7);
  border: 1px dashed #000;
  width: 50%;
  margin: 0;
  pointer-events: none;
}
.altai-theme-admin .window .dropzone-right {
  left: 50%;
}
.altai-theme-admin .wm-window-dropzone-bottom {
  min-height: 1px;
  background-color: #ECECEC;
}
.altai-theme-admin .wm-window-dropzone-bottom.dragover {
  height: 50px;
  background-color: rgba(204, 204, 204, 0.7);
  border: 1px dashed #000;
}
.altai-theme-admin .window-container.minimized .window-header {
  flex-direction: column-reverse;
  flex: 1;
}
.altai-theme-admin .window-container.minimized .window-header .title {
  white-space: nowrap;
  transform: rotate(270deg);
  transform-origin: top left;
  margin-left: 5px;
  /* HACK */
}
.altai-theme-admin .window-container.minimized .window-header .actions-middle {
  align-items: flex-end;
}
